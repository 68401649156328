import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { useEffect } from "react";

import { CbhFeatureFlag, useCbhFlag } from "../FeatureFlags";
import { type Worker } from "../Worker/api/types";
import { useDefinedWorker } from "../Worker/useDefinedWorker";
import {
  type UpdatePushNotificationPermissionParams,
  useUpdatePushNotificationPermission,
} from "./useUpdatePushNotificationPermission";

async function syncOsNotificationPermissions(
  currentWorker: Worker,
  updatePushNotificationPermission: (parameters: UpdatePushNotificationPermissionParams) => void
) {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  const { receive: permission } = await PushNotifications.checkPermissions();

  if (permission === currentWorker?.mobile?.osNotificationPermission) {
    return;
  }

  updatePushNotificationPermission({
    workerId: currentWorker.userId,
    permission,
  });
}

export function useSyncOsPushNotificationPermission() {
  const worker = useDefinedWorker();
  const { mutateAsync: handleUpdateAgent } = useUpdatePushNotificationPermission();

  const enabled = useCbhFlag(CbhFeatureFlag.OS_LEVEL_PUSH_NOTIFICATIONS_SETTINGS, {
    defaultValue: false,
  });

  useEffect(() => {
    if (!enabled) {
      return;
    }

    void syncOsNotificationPermissions(worker, handleUpdateAgent);
  }, [enabled, handleUpdateAgent, worker]);
}
