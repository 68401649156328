import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { put } from "../api/api";
import { environmentConfig } from "../environment/config";
import { logError, logEvent } from "../lib/analytics";
import { APP_V2_APP_EVENTS } from "../lib/analytics/events";
import { useDefinedWorker } from "../Worker/useDefinedWorker";
import { useUpdateWorkerCache } from "../Worker/useUpdateWorkerCache";

export interface UpdatePushNotificationPermissionParams {
  workerId: string;
  permission: string;
}

export const updateAgentResponseSchema = z.object({
  mobile: z.object({
    osPushNotificationPermission: z.string(),
  }),
  _id: z.string(),
  userId: z.string(),
  salesforceId: z.string().optional(),
});

export type UpdateAgentResponseType = z.infer<typeof updateAgentResponseSchema>;

export async function updateWorkerData(params: UpdatePushNotificationPermissionParams) {
  try {
    return await put({
      url: `${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/put`,
      data: { agentId: params.workerId, "mobile.osPushNotificationPermission": params.permission },
      responseSchema: updateAgentResponseSchema,
    });
  } catch (error) {
    logError(APP_V2_APP_EVENTS.PUSH_NOTIFICATION_PERMISSION_SYNC_FAILURE, {
      error,
      metadata: { ...params },
    });
    throw error;
  }
}

export function useUpdatePushNotificationPermission() {
  const worker = useDefinedWorker();
  const updateWorkerCache = useUpdateWorkerCache();

  return useMutation({
    mutationFn: async (parameters: UpdatePushNotificationPermissionParams) => {
      const { permission } = parameters;
      await updateWorkerData(parameters);
      return permission;
    },
    onSuccess: (permission) => {
      logEvent(APP_V2_APP_EVENTS.PUSH_NOTIFICATION_PERMISSION_CHANGED, {
        workerId: worker.userId,
        permission,
      });
      updateWorkerCache({
        mobile: {
          ...worker.mobile,
          osNotificationPermission: permission,
        },
      });
    },
  });
}
